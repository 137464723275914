import React from 'react';
// import { Route, Routes } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import {flexRender, getCoreRowModel, useReactTable, createColumnHelper, getPaginationRowModel, getSortedRowModel, ColumnResizeMode} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import './css/detail.css';
import Slide from './swiper';
import Map from './Map';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import LocalPrintshopIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { useAuthContext } from '../Login/authContext';


// import logo from './logo.svg';
// import './App.css';

// type Post = {
//   userId: number;
//   id: number;
//   title: string;
//   body: string;
// };



export default function Detail(props) {

  
  const now = new Date();
  const year = now.getFullYear();
  
  const { user, load } = useAuthContext();
  const navigate = useNavigate();
  const { tid, rid } = useParams();
  const [data, setData] = useState([]);
  const getPosts = async () => {
    const response = await fetch(
      'https://api-d.homestation.jp/property/getDetail/'+tid+'/'+rid
    //   'http://localhost:8080/property/getDetail/'+tid+'/'+rid
    );
    const a = await response.json();
    console.log(JSON.parse(a))
    setData(JSON.parse(a));

  };
  const getLogs = async () => {
    let body = {
        Tatemono_Id:tid,
        Room_Id:rid,
        id:user.uid
    }

    const response = await fetch(
      'https://api-d.homestation.jp/searchRooms/DetailLogs', {
        // 'http://localhost:8080/searchRooms/DetailLogs', {
        method: 'post',
        body: JSON.stringify(body),
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
    })

  };

  useEffect(() => {
   

    getPosts();
  }, []);
  useEffect(() => {
    getLogs();
  }, [user]);
  
  useEffect(() => {
    if(load && !user){
        navigate('/login')
    }
  }, [load]);
  

  const [showPopup, setShowPopup] = useState(false);
  const handleImageClick = () => {
    setShowPopup(true);
  };

  const handleClose = () => {
    setShowPopup(false);
  };


  
  function getNyuukyo(year, month, day) {
    var hikakuDate = "";
    var now = moment().format("YYYY-MM-DD");
    var disp_day = day;
    // 入居可能日が入っている場合
    if (year !== "" && month !== "" && day !== "" && day !== "-") {
      var jiki;
      if (day === "上旬") {
        jiki = "10";
      } else if (day === "中旬") {
        jiki = "20";
      } else if (day === "下旬") {
        var lastDate = moment(year + month + "01").endOf("month");
        jiki = moment(lastDate).get("date");
      } else {
        // 日付が入力されている場合
        jiki = day;
        disp_day = day + "日";
      }
      hikakuDate = moment(year + month + jiki).format("YYYY-MM-DD");
      if (moment(hikakuDate).isBefore(now)) {
        return "即";
      }
      return year + "年" + month + "月" + disp_day;
    } 
    else {
      return "即";
    }
  // return "即";
  }

  function setsubiCheck(num) {
    if (data[0].setsubi.filter((a) => a.Setsubi_No === num)[0]) {
      return true;
    } else {
      return false;
    }
  }


  function getSetsubisKyouyou(group,flag,name) {
      
    var result = data[0].setsubi.filter(function (value) {
    // console.log(value,group,flag,name,"flag")
      //グループの設備だけを抽出
      if(flag == 0){
        if (value.Setsubi_Group_No == group && (value.Setsubi_No == 38 || value.Setsubi_No == 104 || value.Setsubi_No == 105 || value.Setsubi_No == 106 || value.Setsubi_No == 126 || value.Setsubi_No == 71 || value.Setsubi_No == 72) && value.Setsubi_No != 40) {
        return value;
        }
      }else if(flag == 1 && name === 'heya'){
        if (value.Setsubi_Group_No == group && (value.Setsubi_No != 38 && value.Setsubi_No != 104 && value.Setsubi_No != 105 && value.Setsubi_No != 106 && value.Setsubi_No != 126 && value.Setsubi_No != 71 && value.Setsubi_No != 72) && value.Setsubi_No != 40 &&  value.Setsubi_No != 116) {
          return value;
        }
      }else{
        if (value.Setsubi_Group_No == group && (value.Setsubi_No != 38 && value.Setsubi_No != 104 && value.Setsubi_No != 105 && value.Setsubi_No != 106 && value.Setsubi_No != 126 && value.Setsubi_No != 71 && value.Setsubi_No != 72) && value.Setsubi_No != 40) {
          return value;
        }
      }
    });
//   console.log(result,"結果")
    var array = [];
    result.forEach(function (value) {
      array.push(value.Setsubi_Name);
    });
    return array.join(",");
  }

  
  function getSetsubis(group,comment) {
    var Item = data[0].setsubi
    var Length = data[0].setsubi.length

    function InSetsubi(ID) {
      for (var i = 0; i < Length; i++) {
        // console.log(Item[i],"Item")
        if (Item[i].Setsubi_No === ID) {
          return true;
        }
      }
      return false;
    }
    
    
    var result = data[0].setsubi.filter(function (value) {
      //グループの設備だけを抽出
      //『共用部』(Setsubi_Group_No:11洗濯場)の室内洗濯機置場有(id:69)は『室内設備』に表示
      if (group === 4) {   //室内設備
        if (value.Setsubi_Group_No === 11 && value.Setsubi_No === 69 && comment === "none") {
          return value;
        }
        else if (value.Setsubi_Group_No === group && comment === "none") {
          if (value.Setsubi_Group_No_Sub != 15 && value.Setsubi_No === 41) {
            return false; // 41をフィルタリング
          } else if (value.Setsubi_Group_No_Sub != 15 && value.Setsubi_No === 42) {
            if (InSetsubi(41)) {
              return false; // 42のみをフィルタリングしない
            }
          }else if (value.Setsubi_Group_No_Sub != 15) {
            return value; // その他のアイテムを保持
          }
          
        }
        else if (value.Setsubi_Group_No === group && comment === "kaden") {
          if(value.Setsubi_Group_No_Sub === 15 && value.Setsubi_No != 129 && value.Setsubi_No != 142 && value.Setsubi_No != 448 && value.Setsubi_No != 583){
            return value;
          }
        }
      }
      else if (group === 1) {   //縦型照明付洗面化粧台除く
        if (value.Setsubi_Group_No === group && value.Setsubi_No != 217) {
          return value;
        }
      }
      else if (group === 11) {   //共用部
        if (value.Setsubi_Group_No === group && value.Setsubi_No != 69) {
          return value;
        }
      }else if (group === 9) {   //条件
        if (value.Setsubi_Group_No === 9 && value.Setsubi_No != 48 && value.Setsubi_No != 155) {
          return value;
        }
      } 
      else if (group === 10) {   //温水洗浄便座
        if (value.Setsubi_Group_No === 10){
          if (value.Setsubi_No === 1) {
            return false; // 1をフィルタリング
          } else if (value.Setsubi_No === 2) {
            if (InSetsubi(1)) {
              return false; // 2のみをフィルタリングしない
            }
          }
          return value; // その他のアイテムを保持
        }
        
      } 
      else if (value.Setsubi_Group_No === group) {
        return value;
      }
    });


    var array = [];
    result.forEach(function (value) {
      array.push(value.Setsubi_Name);
    });
    return array.join(",");
  }

  const [ loding, setLoding ] = useState(false);

  async function DownloadDaicho(){  
    setLoding(true)
    // var url = 'http://localhost/laravelapi/public/api/ChukaiDaicho/' + data[0].Tatemono_Id + '/' + data[0].Room_Id
    // var url = 'https://doc.homestation.jp/api/ChukaiDaicho/' + data[0].Tatemono_Id + '/' + data[0].Room_Id
    // var url = 'http://localhost/laravelapi/public/api/NewPostDaicho/' + data[0].Tatemono_Id + '/' + data[0].Room_Id + '/2/1'
    var url = 'https://doc.homestation.jp/api/NewPostDaicho/' + data[0].Tatemono_Id + '/' + data[0].Room_Id + '/2/1'
      var uid = user.uid        
      const response = await fetch(url, {
          method: 'post',
          body: JSON.stringify({uid:uid}),
          // headers: { 'Accept': 'application/pdf' },
          headers: { 'Content-type': 'application/json; charset=UTF-8','Accept': 'application/pdf'  },
      })
      const value = await response.blob();    
      const a = document.createElement('a');
      a.download = data[0].Tatemono_Name + '_' + data[0].Room_Name;
      a.href = URL.createObjectURL(value);
      a.click();
      setLoding(false)
  }

  // console.log(data,"detail")

  return user ? (
  <main>
    {data.map((item, index) => (
    <div key={'detail'+index}>
      <div className='search-details'>
        
        {/* {item.content?<div className="campaign-area-div">
            {!item.content.includes('ゼロ９')?
            <div className="campaign-area">
              {item.content}
              <span>
                {moment(item.end_at).format("YYYY/MM/DD")}までに申込いただいた方限定<br/> 
                {!item.content.includes('ゼロ９')?
                <span>※ただし、申込から1ヶ月以内の契約スタートに限る</span>
                :""}
              </span>
            </div>
            :(item.content.includes('ゼロ９')?
              <div className="campaign-img">
                <img src={`${process.env.PUBLIC_URL}/img/zero9_banner.jpg`} alt="一人暮らしで最初にかかる９コの初期費用がゼロになる　ゼロ９対象物件"/>
              </div> 
            :"")}
          </div>
        :""} */}

        <h1 className="bukken_name">
          {item.Tatemono_Name}-{item.Room_Name}
        </h1>
        
        
        
        <div className='box-first-info'>
          <table className='firstTable'>
            <tbody>
              <tr style={{height:"20px"}}>
                <th>家賃</th>
                <th>共益費</th>
                <th>敷金</th>
                <th>礼金</th>
                <th>駐車場</th>
                <th>間取り</th>
                <th>面積</th>
                <th>入居</th>
              </tr>
              <tr>
                <td><span className='yachin_num'>{item.ChinRyou/10000}</span>万円</td>
                <td><span className='num'>{item.KyouekiHi.toLocaleString()}</span>円</td>
                <td>
                  {item.ShikiKin?
                    <div><span className='num'>{item.ShikiKin}</span>円</div>
                  :(item.ShikiKin === 0?
                    <div>-</div>
                  :(item.ShikiKin_Mon?
                    <div><span className='num'>{item.ChinRyou*item.ShikiKin_Mon}</span>円</div>
                  :""))}
                </td>
                <td>
                  {item.ReiKin?
                    <div><span className='num'>{item.ReiKin}</span>円</div>
                  :(item.ReiKin === 0?
                    <div>-</div>
                  :(item.ReiKin_Mon?
                    <div><span className='num'>{item.ChinRyou*item.ReiKin_Mon}</span>円</div>
                  :""))}
                </td>
                <td><span className='num'>{item.P_Ryou}</span></td>
                <td><span className='num'>{item.Madori_Name}</span></td>
                <td><span className='num'>{item.Senyuu_Menseki}</span>㎡</td>
                <td><span className='num'>{getNyuukyo(item.Nyuukyo_Kanou_Year,item.Nyuukyo_Kanou_Month,item.Nyuukyo_Kanou_Day)}</span></td>
              </tr>

              <tr style={{height:"20px"}}>
                <th>水道料</th>
                <th>保険</th>
                <th>定額クリーニング</th>
                <th>支払仲介料</th>
                <th>AD広告料</th>
                <th>事務手数料</th>
                <th colSpan={2}>鍵所在</th>
              </tr>
              <tr>
                <td><span className='num'>{item.SuidouRyou}</span></td>
                <td><span className='num'>{item.Hoken}</span></td>
                <td><span className='num'>{item.Teigaku_Cleaning}</span></td>
                {item.shiharai_Chukai != 0? <td><span className='num'>{item.shiharai_Chukai}</span>%</td>
                : <td><span className='num'>-</span></td>}
                <td><span className='num'>{item.ad_flag == 1 ? item.ad_fee.toLocaleString() :
                                        item.ad_flag == 2 ? item.ad_month :
                                        "" }</span>
                                        {item.ad_flag == 1 ? '円' :
                                            item.ad_flag == 2 ? 'ヵ月' :
                                            "-" }</td>
                <td><span className='num'>{item.jimu_tesuryou == 0 ? '-' : '11,000円(税込)'}</span></td>
                <td colSpan={2}><span className='num'>{item.Kagi_Shozai_Name ? item.Kagi_Shozai_Name : '要確認'}</span></td>
              </tr>
            </tbody>
          </table>

          

          <div className='ImageFlexs'>
            <div className='inner-img-prop'>
              <Slide tid={item.Tatemono_Id} rid={item.Room_Id} 
              img={item.img} vr={item.vr_code} movie={item.youtube_id} kyouyou={item.kyouyou}/>
            </div>
            <div className='inner-img-floor'>
              <div className="img-floor">
                <a onClick={handleImageClick} style={{ cursor: 'pointer' }}>
                  <img alt="間取" src={'https://img.global-center.co.jp/v2/rooms/' + item.Tatemono_Id +'/' +item.Room_Id +'/8/1/253/405/0'}/>
                </a>
                <div className='plus'><AddCircleIcon/></div>
              </div>
              <div className='pdfBtn' style={{cursor:'pointer'}} onClick={()=>DownloadDaicho()}>
                {loding ? 
                <CircularProgress color="inherit" style={{width:"35px",height:"35px"}} />
                :<LocalPrintshopIcon/>}
                <span>物件資料ダウンロード</span>
              </div>
              <div className='ButtonBoxs'>
                <div className='btn ippan' style={{cursor:'pointer'}} 
                    onClick={()=>window.location.href='https://doc.homestation.jp/api/applicationform/'+data[0].Tatemono_Id+'/'+data[0].Room_Id+'/1'}
                    // onClick={()=>window.location.href='http://localhost/laravelapi/public/api/applicationform/'+data[0].Tatemono_Id+'/'+data[0].Room_Id+'/1'}
                  >
                  <LocalPrintshopIcon/>
                  <span>個人申込書</span>
                </div>
                <div className='btn houjin' style={{cursor:'pointer'}} 
                  onClick={()=>window.location.href='https://doc.homestation.jp/api/applicationform/'+data[0].Tatemono_Id+'/'+data[0].Room_Id+'/2'}
                  // onClick={()=>window.location.href='http://localhost/laravelapi/public/api/applicationform/'+data[0].Tatemono_Id+'/'+data[0].Room_Id+'/2'}
                >  
                  <LocalPrintshopIcon/>
                  <span>法人申込書</span>
                </div>
              </div>

              <div className='telBox'>
                <p>物件のお問い合わせはこちら</p>
                <div className='telContact'>
                  <LocalPhoneIcon/>
                  <span>090-6015-8175</span><span className='manager-name'>(担当：小野)</span>
                </div>
                {/* <div className='telContact'>
                  <LocalPhoneIcon/>
                  <span>070-5343-5942</span><span className='manager-name'>(担当：景山)</span>
                </div> */}
              </div>

            </div>
          </div>
        </div>
        

        <div className="box-btm-info">
          <ul className="tbl-prop">
            <li className="item-tbl-prop ttl">
              <p className="txt-ttl">建物情報</p>
            </li> 
            <li className="item-tbl-prop">
              <dl>
                <dt>階層</dt> 
                <dd>
                { item.Kai }階{ item.Room_Name } /
                { item.Kaidate_Chijou }階建
                </dd>
              </dl>
            </li>
            <li className="item-tbl-prop">
              <dl>
                <dt>竣工年月</dt> 
                <dd>{item.Shunkou_Year}年{item.Shunkou_Month}月</dd>
              </dl>
            </li> 
            <li className="item-tbl-prop">
              <dl>
                <dt>建物種別</dt>
                <dd>{item.Tatemono_Shubetsu_Name}</dd>
              </dl>
            </li>
            <li className="item-tbl-prop last">
              <dl>
                <dt>構造</dt>
                <dd>{item.Kouzou_Name}</dd>
              </dl>
            </li>
            <li className="item-tbl-prop ttl">
              <p className="txt-ttl">環境情報</p>
            </li>
            <li className="item-tbl-prop">
              <dl>
                <dt>向き</dt>
                <dd>{item.Room_Muki_Name}</dd>
              </dl>
            </li>
            <li className="item-tbl-prop">
              <dl>
                <dt>所在地</dt> 
                <dd>{item.Shikuchoson+item.Choiki+item.Other_Address}
                  <a href="#MAP"
                  className="nuxt-link-exact-active nuxt-link-active">MAP</a>
                </dd>
              </dl>
            </li>
            <li className="item-tbl-prop">
              <dl>
                <dt>取引態様</dt>
                <dd>{item.Torihiki_Keitai_Name}</dd>
              </dl>
            </li> 
            <li className="item-tbl-prop ">
              <dl>
                <dt>交通</dt>
                <dd>
                  {item.Koutsuu_1} 徒歩{item.Koutsuu_1_Min}分<br/>
                  {item.Koutsuu_2} 徒歩{item.Koutsuu_2_Min}分<br/>
                  {item.Koutsuu_3} 徒歩{item.Koutsuu_3_Min}分
                </dd>
              </dl>
            </li>
            <li className="item-tbl-prop last">
              <dl>
                <dt>校区</dt>
                <dd>{item.Shougakkou}{item.Chuugakkou?"、":""}{item.Chuugakkou}</dd>
              </dl>
            </li> 
            <li className="item-tbl-prop ttl">
              <p className="txt-ttl">設備情報</p>
            </li>
            <li className="item-tbl-prop facility">
              <ul className="list-facility">
                <li className={'item-facility pet'+ (setsubiCheck(45)?" active":"")}>
                  <img src={`${process.env.PUBLIC_URL}/img/icon/icon_pet.png`} alt='ペット'/>
                  <span>ペット可</span>
                </li>
                <li className={'item-facility bath'+ (setsubiCheck(20)?" active":"")}>
                  <img src={`${process.env.PUBLIC_URL}/img/icon/icon_bath.png`} alt='バス'/>
                  <span>バス･トイレ別</span>
                </li>
                <li className={"item-facility autolock"+ (setsubiCheck(37)?" active":"")}>
                  <img src={`${process.env.PUBLIC_URL}/img/icon/icon_autolock.png`} alt='オートロック'/>
                  <span>オートロック</span>
                </li> 
                <li className={"item-facility elevator"+ (item.HaveElevator === 1?" active":"")}>
                  <img src={`${process.env.PUBLIC_URL}/img/icon/icon_elevator.png`} alt='エレベーター'/>
                  <span>エレベーター</span>
                </li> 
                <li className={"item-facility toilet"+ (setsubiCheck(1)?" active":"")}>
                  <img src={`${process.env.PUBLIC_URL}/img/icon/icon_toilet.png`} alt='トイレ'/>
                  <span>温水暖房便座</span>
                </li>
                
                <li className={"item-facility air-conditioner"+ (
                  setsubiCheck(7) ||setsubiCheck(8) ||setsubiCheck(58) ||setsubiCheck(115) ||
                  setsubiCheck(119) ||setsubiCheck(121) ||setsubiCheck(151)?" active":"")}>
                  <img src={`${process.env.PUBLIC_URL}/img/icon/icon_air-conditioner.png`} alt='エアコン'/>
                  <span>エアコン付き</span>
                </li> 
                <li className={"item-facility tv"+ (setsubiCheck(41)?" active":"")}>
                  <img src={`${process.env.PUBLIC_URL}/img/icon/icon_tv.png`} alt='TVドアホン'/>
                  <span>TVドアホン</span>
                </li> 
                <li className={"item-facility wash"+ (setsubiCheck(69)?" active":"")}>
                  <img src={`${process.env.PUBLIC_URL}/img/icon/icon_wash.png`} alt='室内選択'/>
                  <span>室内洗濯</span>
                </li>
                <li className={"item-facility washstand"+ (setsubiCheck(77)?" active":"")}>
                  <img src={`${process.env.PUBLIC_URL}/img/icon/icon_washstand.png`} alt='独立洗面台'/>
                  <span>独立洗面台</span>
                </li>
                <li className={"item-facility internet"+ (setsubiCheck(16)?" active":"")}>
                  <img src={`${process.env.PUBLIC_URL}/img/icon/icon_internet.png`} alt='ネット無料'/>
                  <span>インター<br/>ネット無料</span>
                </li>
                <li className={"item-facility rice"+ (setsubiCheck(3)?" active":"")}>
                  <img src={`${process.env.PUBLIC_URL}/img/icon/icon_rice.png`} alt='追い炊き'/>
                  <span>追い焚き</span>
                </li>
                <li className={"item-facility stove"+ (
                  setsubiCheck(22) ||setsubiCheck(23) ||setsubiCheck(28) ||
                  setsubiCheck(30) ||setsubiCheck(59)?" active":"")}>
                  <img src={`${process.env.PUBLIC_URL}/img/icon/icon_stove.png`} alt='コンロ２口以上'/>
                  <span>コンロ<br/>２口以上</span>
                </li>
              </ul>
            </li> 
            <li className="item-tbl-prop">
              <dl>
                <dt>キッチン/バス・トイレ</dt>
                <dd>{ getSetsubis(3) }/{ getSetsubis(1) }・{ getSetsubis(10) }</dd>
              </dl>
            </li>
            <li className="item-tbl-prop">
              <dl>
                <dt>室内設備</dt> 
                <dd>{getSetsubis(4,"none")}</dd>
              </dl>
            </li> 
            {getSetsubis(4,'kaden')?<li class="item-tbl-prop">
              <dl>
                <dt>家具家電</dt>
                <dd>{getSetsubis(4,"kaden")}</dd>
              </dl>
            </li>:""}
            <li className="item-tbl-prop">
              <dl>
                <dt>部屋の特徴</dt>
                <dd>{getSetsubisKyouyou(8,1,"heya")}</dd>
              </dl>
            </li>
            <li className="item-tbl-prop last">
              <dl>
                <dt>共用部</dt>
                <dd>{getSetsubisKyouyou(8,0)}{getSetsubisKyouyou(11,0) ? ',' : ''}{getSetsubisKyouyou(11,0)}
                    {item.setsubi.find(x => x.Setsubi_No == 17 || x.Setsubi_No == 18 || x.Setsubi_No == 372) ? ",駐車場2台以上" : ''}
                </dd>
              </dl>
            </li>
            <li className="item-tbl-prop ttl">
              <p className="txt-ttl">契約情報</p>
            </li>
            <li className="item-tbl-prop">
              <dl>
                <dt>条件</dt>
                <dd>{getSetsubis(9)}</dd>
              </dl>
            </li> 
            {Object.keys(item).indexOf('teikishakka_year') != -1?<li className="item-tbl-prop last">
              <dl>
                <dt>契約期間</dt> 
                <dd>{Object.keys(item).indexOf('teikishakka_year') != -1 ? !item.teikishakka_year && !item.teikishakka_period_date ? '普通借家契約　24ヶ月間' : item.teikishakka_period_date ? '定期借家契約' +moment(item.teikishakka_period_date).format('YYYY年M月D日') : '契約日より' +item.teikishakka_period_date + '年間' : '' }</dd>
              </dl>
            </li>:""}
          </ul> 

          <div className="box-outline">
            <h2 className="ttl-outline">
              {item.aos_comment_1}
            </h2>
            <div className="txt-outline">
              {item.kanri_corp_no == 5?<span>
              物件現地で待ち合わせ、ご案内させていただくことが可能です！店舗へご来店いただくまとまったお時間がなかなか取れないという方もお気軽にご相談ください♪
              また、オンラインでの物件提案やご内見も可能です♪ZOOMやLINE、Facetimeなどのツールを使い、営業スタッフがお客様のご希望に沿った物件のご提案をいたします。もちろん、気になる物件が見つかった場合は営業スタッフが現地に向かい、オンラインでお部屋をご案内致します。県外にお住まいの方や、お仕事・育児の都合などによりご来店が難しい方…など、ご来店いただかなくても営業スタッフが精一杯お客様にピッタリのお部屋をお探しいたしますので、ご安心くださいませ(^^)
              初期費用・家賃はクレジットカード決済も可能です。（仲介手数料・住宅保険料は除く）急なお引っ越しが決まった場合にも、計画的なお支払いができるので簡単にお手続きを進めることができます！また、カード決済の場合は通常のお買い物と同じようにご利用ポイントが付く点もお得です♪
              </span>:""}
              {item.aos_comment_2 ? item.aos_comment_2 :""}
              {item.PR_Comment_Web ? item.PR_Comment_Web :""}
            </div>
          </div>
          
        </div>


        <div className='mapBox' id='MAP'>
          <div className="tab-area">
            <div className='tab-map'>
              <img src={`${process.env.PUBLIC_URL}/img/mapicon/icon_map.png`} alt='MAP'/>
              MAP
            </div>
          </div>
          <Map Ido={item.Ido} Keido={item.Keido}/>
        </div>      
      </div>
    
      <p className="copyright">
        © 2003-{year} GLOBAL CENTER,INC
      </p>

      <Modal className='modal' show={showPopup} onHide={handleClose}>
        <Modal.Body className='modalBody'>
          <img
            src={'https://img.global-center.co.jp/v2/rooms/' + item.Tatemono_Id +'/' +item.Room_Id +'/8/1/500/600/0'} 
            // src={`${process.env.PUBLIC_URL}/img/madori.jpg`}
            style={{ width: '100%' }} alt="間取"
          />
        </Modal.Body>
        <div className='closeBtnBox'>
          <IconButton
            className='closeBtn' style={{color:"#fff"}}
            onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </div>
        
      </Modal>

    </div>
    ))}
  </main>

  ) : (<></>);
}



